* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
  width: 100%;
  min-height: 100vh;
}

.App .navbar {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  background: rgba(29, 161, 242, 1);
}

.App .navbar .nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.App .navbar .nav-links>a {
  color: #fff;
  text-decoration: none;
}


h2 {
  color: black;

}


/*  

!!
!!
!!
!!
!!
!!

*/